import ApiRest from './ApiRest'

const apiRest = new ApiRest(process.env.REACT_APP_APIURL)

const getProd = async(opts) => {
    let qs = ''
    if(opts){
        const q = []
        for (const key in opts) {
            if (Object.hasOwnProperty.call(opts, key)) {
                const element = opts[key];
                q.push(`${key}=${element}`)
            }
        }
        qs = `?${q.join('&')}`
    }
    const res = await apiRest.call(`/foglietti/products${qs}`, {
        method: 'get',
    })
    return res.data.products
}

const addProd = async(data) => {
    const res = await apiRest.call(`/foglietti/products`, {
        method: 'post',
        data: data
    })
    return res.data
}

const tx = {
    getProd: getProd,
    addProd: addProd
}

export default tx
