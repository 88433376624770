import HttpReq from './HttpReq'

class ApiRest {
    constructor(baseUrl, options) {
        this.baseUrl = baseUrl
        this.defaultOptions = options
    }

    async call(endpoint, options) {
        const defaults = {
            method: 'get',
            responseType: 'json',
            responseEncoding: 'utf8',
            timeout: 60000
        }

        const opts = { ...defaults, ...this.defaultOptions, ...options }
        opts.url = `${this.baseUrl}${endpoint}`
        const http = new HttpReq(opts)
        const response = await http.fetch()
        return response
    }
}

export { ApiRest as default }
