import React from 'react'
import { Table } from "semantic-ui-react"
import Dates from '../../libs/Dates'
import Numbers from '../../libs/Numbers'

function TxRows(props){
    // console.log(props)
    const txdata = props.txdata
    //txdata.sort((a,b) => a.dateTs > b.dateTs)
    return   <Table celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Date</Table.HeaderCell>
        <Table.HeaderCell>Operation</Table.HeaderCell>
        <Table.HeaderCell>In</Table.HeaderCell>
        <Table.HeaderCell>Out</Table.HeaderCell>
        <Table.HeaderCell>Fee</Table.HeaderCell>
        {
            props.showUser === true
            ? <Table.HeaderCell>User</Table.HeaderCell>
            : null
        
        }
      </Table.Row>
    </Table.Header>

    <Table.Body>
        {
            txdata.map((i, pos)=>{
                let d = new Date()
                d.setTime(i.dateTs * 1000)
                const _date = Dates.formatDayTime(d)
                let _operation = ''
                let _in = ''
                let _out = ''
                let _fee = Numbers.toCurrency(i.fee || 0)
                let _user = i.user
                let _positive = false
                let _negative = false
                switch(i.type){
                    case 'buy.token':
                        _operation = 'Token BUY'
                        _out = `${Numbers.toFloat(i.tokens)} ${i.currencyCode}`
                        _in = `${Numbers.toCurrency(i.fiat)} EUR`
                        break
                    case 'deposit.fiat':
                        if (i.amount > 0){
                            _operation = 'FIAT Deposit'
                            _positive = true
                            _in = `${Numbers.toCurrency(i.amount)} EUR` 
                        } else {
                            _operation = 'FIAT Withdraw'
                            _negative = true
                            _out = `${Numbers.toCurrency(i.amount)} EUR`
                        }
                        // _out = `${Numbers.toCurrency(i.fiat)} EUR`
                        break
                    case 'sell.token':
                        _operation = 'TOKEN Sell'
                        // _positive = true
                        _in = `${Numbers.toFloat(i.tokens)} ${i.currencyCode}`
                        _out = `${Numbers.toCurrency(i.fiat)} EUR`
                        break
                    default:
                        _operation = i.type
                        _in = 0
                        _out = 0

                }

                return <Table.Row positive={_positive} negative={_negative} key={i.id}>
                    <Table.Cell>{_date}</Table.Cell>
                    <Table.Cell>{_operation}</Table.Cell>
                    <Table.Cell>{_in}</Table.Cell>
                    <Table.Cell>{_out}</Table.Cell>
                    <Table.Cell>{_fee} EURO</Table.Cell>
                    {
                        props.showUser === true
                        ? <Table.Cell>{_user}</Table.Cell>
                        : null
                    
                    }
              </Table.Row>
            })
        }   
      {/* <Table.Row>
        <Table.Cell>No Name Specified</Table.Cell>
        <Table.Cell>Unknown</Table.Cell>
        <Table.Cell negative>None</Table.Cell>
      </Table.Row>
      <Table.Row positive>
        <Table.Cell>Jimmy</Table.Cell>
        <Table.Cell>
          <Icon name='checkmark' />
          Approved
        </Table.Cell>
        <Table.Cell>None</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Jamie</Table.Cell>
        <Table.Cell>Unknown</Table.Cell>
        <Table.Cell positive>
          <Icon name='close' />
          Requires call
        </Table.Cell>
      </Table.Row>
      <Table.Row negative>
        <Table.Cell>Jill</Table.Cell>
        <Table.Cell>Unknown</Table.Cell>
        <Table.Cell>None</Table.Cell>
      </Table.Row> */}
    </Table.Body>
  </Table>
 

}

export default TxRows