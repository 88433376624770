import React from 'react'
import { Button, Header, Modal, Input, Label, Message } from 'semantic-ui-react'
import Numbers from '../libs/Numbers'

export default class TokenBuy  extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            amountTokens: 0,
            amountEuro: 0,
            amountFee: 0, //always in FIAT
            amountFeeGain: 0, //always in FIAT
            amountFeeCost: 0, //always in FIAT                      
            error: false
        }
        this.swapEuro = this.swapEuro.bind(this)
        this.swapToken = this.swapToken.bind(this)
    }

    componentDidUpdate(prevProps){
      if(
        prevProps.tokenData.code !== this.props.tokenData.code){
        this.setState({
          amountTokens: 0,
          amountEuro: 0,
          amountFee: 0,
          amountFeeGain: 0, //always in FIAT
          amountFeeCost: 0, //always in FIAT                    
          error: false
        })
      }
    }

    async swapEuro(e){
      let value = parseFloat(e.target.value)
      value = isNaN(value) ? 0 : value
      if(value<0){
        return
      }
      const fee = (value * (this.props.tokenData.fee_gain+this.props.tokenData.fee_workload))
      const oth = this.props.tokenData.toFiat * (value-fee)
      if(value>this.props.max){
        this.setState({
          error: true
        })
      } else {
        this.setState({
          amountTokens: oth,
          amountEuro: value,
          amountFee: fee,
          error: false
        })
      }
    }

    async swapToken(e){
      let value = parseFloat(e.target.value)
      value = isNaN(value) ? 0 : value
      if(value<0){
        return
      }      
      let oth = parseFloat(value / this.props.tokenData.toFiat)
      
      const fee_gain = oth * this.props.tokenData.fee_gain
      const fee_cost = oth * this.props.tokenData.fee_workload
      const fee = fee_gain + fee_cost      
      oth = oth + fee
      oth = parseFloat(oth.toFixed(2))
      if(oth>this.props.max){
        this.setState({
          error: true
        })
      } else {      
        this.setState({
          amountTokens: value,
          amountEuro: oth,
          amountFee: fee,
          error: false
        }) 
      }     
    }
    render(){

        return <Modal
              onClose={() => this.props.close()}
              open={this.props.open}
              >
        <Modal.Header>Buy {this.props.tokenData.name}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Header>Easy {this.props.tokenData.code} interface</Header>
            <div>
            this place represent a basic TOKEN interface. We are making math with some dimensions like:
            <ul>
              <li>FIAT Exchange rate: a floating point number for exchange rate (this case is {this.props.tokenData.toFiat} that means 1 EUR = {this.props.tokenData.toFiat} {this.props.tokenData.code})</li>
              <li>FEE Gain: a percentage on the transaction to represent company profit (this case is {Numbers.toPercentage(this.props.tokenData.fee_gain)})</li>
              <li>FEE Workload: a percentage on the transaction to represent company cost (this case is {Numbers.toPercentage(this.props.tokenData.fee_workload)})</li>
            </ul>
            <p>
              Every transaction will exchange FIAT for TOKEN with the FEE Gain+Workload (this case is {Numbers.toPercentage(this.props.tokenData.fee_gain+this.props.tokenData.fee_workload)}).<br/>
              On this demo the fee is always calculated on the FIAT.<br /><br />
              <b>Amounts are rounded with NO BRAIN algorithm...</b>
              </p>
            </div>
            <h2>
                How Much {this.props.tokenData.code} do you want to buy? (fox max {this.props.max}&euro;) <br />
                <Input labelPosition='right' type='text' placeholder='Amount'>
                    <Label basic style={{width: '100px'}}>{this.props.tokenData.code}</Label>
                    <input 
                        value={this.state.amountTokens}
                        onChange={this.swapToken}
                        />
                </Input>                
                <br />
                <Input labelPosition='right' type='text' placeholder='Amount'>
                    <Label basic style={{width: '100px'}}>EURO</Label>
                    <input 
                        value={this.state.amountEuro}
                        onChange={this.swapEuro}
                        />
                </Input>                
            </h2>
            {
              this.state.error
              ? <Message warning>
                  <Message.Header>You must deposit EURO!</Message.Header>
                  <p>EURO Amount not enought.</p>
                </Message>  
              : null
            }
            {
              this.state.amountFee > 0
              ? <p>transaction cost (fee): {this.state.amountFee}&euro;</p> 
              : null
            }
               
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => {
            this.props.close()
            
            }}>
            Nope
          </Button>
          <Button
            content="Buy"
            labelPosition='right'
            icon='checkmark'
            onClick={() => {
              this.props.buy({
                tokens: this.state.amountTokens,
                fiat: this.state.amountEuro,
                fee: this.state.amountFee, 
                fee_gain: this.state.amountFeeGain,
                fee_cost: this.state.amountFeeCost,                  
                currencyCode: this.props.tokenData.code            
              })
            }}
            positive
          />
        </Modal.Actions>
      </Modal>
    }
}