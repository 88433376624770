import React from "react"
import {Table} from "semantic-ui-react"
import PageTemplate from "./PageTemplate"
import Numbers from '../libs/Numbers'
import API from '../libs/API'
// import Storage from '../libs/Storage'

import TxRows from '../components/TxRows'
export default class CompanyHistory extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        tx: [],
        aggregationsRows: [],
      }
    }

    async componentDidMount(){
      const { tx } = await API.tx.getTx()
      const aggregationsRows =  []
      const fee_gaint = tx.reduce((p, c)=>{
        const fee_gain = (c.fee_gain) ? c.fee_gain : 0
        return p = fee_gain
      }, 0)
      const fee_cost = tx.reduce((p, c)=>{
        const fee_cost = (c.fee_cost) ? c.fee_cost : 0
        return p = fee_cost
      }, 0)
      aggregationsRows.push({
        first: 'Total Transactions',
        second: `${tx.length}`
      })
      aggregationsRows.push({
        first: 'GAIN (FROM FEE)',
        second: `${Numbers.toCurrency(fee_gaint)} EURO`
      })
      aggregationsRows.push({
        first: 'COSTS (FROM FEE)',
        second: `${Numbers.toCurrency(fee_cost)} EURO`
      })
      this.setState({
        tx,
        aggregationsRows
      })
    }

    render() {
      return <PageTemplate
                  page='Company'
              >

              <p>
                <b>COMPANY ONLY PAGE</b>
                <br />
                Users can NOT view this page
              </p>

              <p>
                This represent a kind of dashboard for exploring transactions and view GAIN / COST
              </p>
              <h1>GAIN</h1>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Entity</Table.HeaderCell>
                    <Table.HeaderCell>Count</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {
                    this.state.aggregationsRows.map(i=>{
                      return                   <Table.Row>
                        <Table.Cell>{i.first}</Table.Cell>
                        <Table.Cell>{i.second}</Table.Cell>
                      </Table.Row>
                    })
                  }

                </Table.Body>

              </Table>

                <h1>Transactions</h1>

                <TxRows showUser={true} txdata={this.state.tx}/>


              </PageTemplate>
    }
}
