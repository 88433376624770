import axios from 'axios';

class HttpReq {
    constructor(opts) {
        const defaults = {
            method: 'get',
            responseType: 'json',
            responseEncoding: 'utf8',
            timeout: 0
        }
        this.opts = { ...defaults, ...opts }
        this.response = {
            code: null,
            data: null,
            errors: false
        }
    }

    async fetch() {
        try {
            const r = await axios(this.opts)
            this.response = {
                status: r.status,
                data: r.data,
                errors: false
            }
        } catch (e) {
            this.response = {
                status: e.response ? e.response.status : false,
                data: e.response
                    ? e.response.data.message
                    : e.toString().slice(7),
                errors: true
            }
            if (e.response && e.response.status === 401) {
                //message.error(e.toString())
                window.location.hash = '/landing'
            }
            //message.error(e.toString())
            throw e
        }
        return this.response
    }
}

export { HttpReq as default }
