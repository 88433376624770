import React  from 'react'
import { Card} from 'semantic-ui-react'
// import Dates from '../../libs/Dates'
// import Numbers from '../../libs/Numbers'

function ForgeItem(props){

    const name =props.item.prod.name
    const weight = props.item.prod.weight
    const description = props.item.prod.description
    const buyDate = props.item.date
    const nftId = props.item.id


    return <Card fluid style={{textAlign: 'left'}}>
        <Card.Content>
            <Card.Header>
                <h1>{name}</h1> 
                <p>
                    {weight}g metal
                </p>    
            </Card.Header>
            <Card.Description>
                {description}
            </Card.Description>
            <Card.Meta>
            purchase date: {buyDate}<br />
            purchase price: {props.item.cost.selled_price} {props.item.cost.currency}<br />
            NFT CODE {nftId}
            {
                props.showUser
                ? <><br/>User: {props.item.username}</>
                : null
            }
            </Card.Meta>
        </Card.Content>
    </Card>
}

function ProductsHome(props){
// console.log('ProductsHome', props)
    return   <Card.Group>
        {
        props.products.map((i, pos)=>{
            return <ForgeItem key={i.name} pos={pos} item={i} showUser={props.showUser || false}

            />
        })
        }
    </Card.Group>
}

export default ProductsHome