const isJSON = require('is-valid-json')

const Storage = {
  deleteAll: () => localStorage.clear(),
  delete: (key) => localStorage.removeItem(key),
  set: (key, value) => {
    if (typeof value === 'object') {
      value = JSON.stringify(value)
    }
    return localStorage.setItem(key, value)
  },
  get: async (key) => {
    const item = await localStorage.getItem(key)
    if (isJSON(item)) {
      return JSON.parse(item)
    }
    return item
  },
}

export default Storage