
const Numbers = {
  toCurrency: (value) => {
    return `${parseFloat(value).toFixed(2)}`
  },
  toPercentage: (value) => {
    return `${parseFloat(value).toFixed(2)}%`
  },
  toFloat: (value) => {
    value = parseFloat(value) || 1
    return `${parseFloat(value).toFixed(8)}`
  },
  getRandomFloat(min, max, decimals) {
    const str = (Math.random() * (max - min) + min).toFixed(decimals);
    return parseFloat(str);
  }  
}

export default Numbers