import React from "react"

import PageTemplate from "./PageTemplate"

// import Dates from '../libs/Dates'
// import Numbers from '../libs/Numbers'
import API from '../libs/API'
import Storage from '../libs/Storage'
import TxRows from '../components/TxRows'
import StatsRows from '../components/StatsRows'

export default class History extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        username: '',
        userdata: {},
        tx: []
      }
    }


    async componentDidMount(){
      const username = await Storage.get('username')
      const userdata = await Storage.get('userdata')
      
      let tx = []
      try {
        tx = await API.tx.getTx({user: username})
      } catch (e) {
        console.log('tx not working')
      }
      await Storage.set('userdata', userdata)
      this.setState({
          username,
          userdata,
          tx: tx.tx
      })
  }

    render() {
      return <PageTemplate
                  page='History'
              >
                <div>
                  This page is the place where user can see his/her transactions history
                </div>
                {
                  this.state.tx.length > 0
                  ? <>
                    <h2>Stats</h2>
                    <p>
                      Stats are intended for Investors... this is the place where you can find useful info about buiyng and selling price and other interesting number user can use for planning his activities
                    </p>
                    <StatsRows txdata={this.state.tx} />
                  </>
                  : null
                }
                {
                  this.state.tx.length > 0
                  ? <>
                    <h2>Transactions</h2>
                    <p>
                      This is intend for surfing user's history of every action he/she took.
                    </p>
                    <TxRows txdata={this.state.tx} />
                  </>
                  : null
                }
              </PageTemplate>
    }
}
