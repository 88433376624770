import ApiRest from './ApiRest'

const apiRest = new ApiRest(process.env.REACT_APP_APIURL)

const getAll = async(username) => {
    const res = await apiRest.call(`/foglietti/users`, {
        method: 'get',
    })
    return res.data
}

const getData = async(username) => {
    const res = await apiRest.call(`/foglietti/users/${username}`, {
        method: 'get',
    })
    return res.data
}

const setData = async(username, data) => {
    const res = await apiRest.call(`/foglietti/users/${username}`, {
        method: 'put',
        data: data
    })
    return res.data
}

const users = {
    getAll: getAll,
    getData: getData,
    setData: setData
}

export default users
