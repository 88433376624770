const Dates = {}

Dates.formatDayTime = (date) => {
	return `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)} ${(`0${date.getHours()}`).slice(-2)}:${(`0${date.getMinutes()}`).slice(-2)}:${(`0${date.getSeconds()}`).slice(-2)}`
}

Dates.formatTs = (date) => {
	return `${parseInt(date.getTime() / 1000, 10)}`
}

export default Dates