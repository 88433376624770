import React from "react";
import { Button, Card } from "semantic-ui-react";
import Numbers from '../../libs/Numbers'

export default class Token extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            code: props.code,
            name: props.name
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.code !== this.props.code){
            this.setState({
                code: this.props.code,
                name: this.props.name    
            })
        }
        // if(this.props.usertoken.amount !== prevProps.usertoken.amount){
        //     console.log('LOL')
        // }
    }

    render() {
        const exrate = this.props.tokendata.toFiat
        const fees = this.props.tokendata.fee_workload + this.props.tokendata.fee_gain
    return     <Card fluid className="tokenCard">
    <Card.Content>

      <Card.Header>{Numbers.toCurrency(this.props.usertoken ? this.props.usertoken.amount : 0)} {this.props.tokendata.code}</Card.Header>
      <Card.Meta>{this.props.tokendata.name}</Card.Meta>
        <Card.Description>
        Exchange Informations<br />
        1.00 EUR = {Numbers.toFloat(exrate)} {this.props.tokendata.code}<br />
        {Numbers.toPercentage(fees)} Fees
        </Card.Description>
    </Card.Content>
    <Card.Content extra>
      <div className='ui three buttons'>
        <Button basic color='green' onClick={() => this.props.buyTokenModal(this.props.tokendata)}>
          Buy
        </Button>
        <Button basic color='red' onClick={() => {
            const max = (this.props.usertoken && this.props.usertoken.amount) ? this.props.usertoken.amount : 0
            this.props.sellTokenModal(this.props.tokendata, max)
            }}>
          Sell
        </Button>
        <Button basic color='blue' onClick={() => {
            const max = (this.props.usertoken && this.props.usertoken.amount) ? this.props.usertoken.amount : 0
            this.props.sendTokenModal(this.props.tokendata, max)
            }}>
          Send
        </Button>
      </div>
    </Card.Content>
  </Card>
    }
}
