import React from "react"
// import {Button} from "semantic-ui-react"
import PageTemplate from "./PageTemplate"
// import Dates from '../libs/Dates'
// import Numbers from '../libs/Numbers'
// import API from '../libs/API'
// import Storage from '../libs/Storage'
import ProductsHome from '../components/ProductsHome'
import API from "../libs/API"

export default class CompanyRealVault extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        products: [],
        totalWeight: 0
      }
    }

    async componentDidMount(){
      const products = await API.products.getProd()
      const totalWeight = products.reduce((total, item)=>{
        return total + item.prod.weight
      }, 0)
      this.setState({
        products,
        totalWeight,
      })
    }

    render() {
      return <PageTemplate
                  page='Company'
              >
                <h1>The Forge - Real Vault</h1>
                <p className="pageinfo">
                This is a Company only page where we can observe the physical Vault.
                <br />
                <br />
                When users buy a products we'll create a new NFT token owned by the user and we store the object in our vault.<br/>
                We should monetize the real Vault cost and find a way to reverse the cost on the user<br />

                </p>
                {
                  this.state.products.length > 0
                  ? <>
                      <h2>NFTs</h2>
                      <h3>Total Metal: {this.state.totalWeight} grams</h3>
                      <ProductsHome products={this.state.products} showUser={true}/>
                  </>
                  : null
                }
                <br />

              </PageTemplate>
    }
}
