import React from "react"
import {Button} from "semantic-ui-react"
import PageTemplate from "./PageTemplate"
// import Dates from '../libs/Dates'
// import Numbers from '../libs/Numbers'
// import API from '../libs/API'
import Storage from '../libs/Storage'
import ForgeTable from '../components/ForgeTable'
import API from "../libs/API"

export default class CompanyForge extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        products: [],
        tokens: [],
        config: null
      }
      this.saveProducts = this.saveProducts.bind(this)
    }

    async componentDidMount(){
      const config = await Storage.get('config')
      //console.log(config)
      this.setState({
        config: config,
        products: config.products,
        tokens: config.tokens
      })
    }

    async saveProducts(products){
      const newConf = this.state.config
      newConf.products = products
      this.setState({
        products: products,
        config: newConf
      })
      await Storage.set('config', newConf)
      await API.config.setC(newConf)
    }

    render() {
      return <PageTemplate
                  page='Company'
              >
                <h1>The Forge - Company Dashboard</h1>
                <p className="pageinfo">
                This is a Company only page where we can create products.
                <br />
                <br />
                Products are intended as object in real world made of metal and have a specifig weight and a name.<br />
                When users buy a products we'll create a new NFT token owned by the user and we store the object in our vault.<br/>
                When user want to keep the product we swap the real object for the token (and then we burn it... the token, not the object nor the user :P)<br />

                </p>
                {
                  this.state.products.length > 0
                  ? <>
                    <h2>products</h2>
                    <ForgeTable 
                      saveProducts={this.saveProducts}
                      products={this.state.products}
                      tokens={this.state.tokens}
                      />
                  </>
                  : null
                }
                <br />
              <Button 
                onClick={(e)=>{
                  const ps = this.state.products
                  ps.push({
                    name: 'New Product',
                    description: 'add description',
                    cost: []
                  })
                  this.saveProducts(ps)
                }}
              >Add New Product</Button>
              </PageTemplate>
    }
}
