import React from "react";
import PageTemplate from './PageTemplate'
import Storage from '../libs/Storage'
import Dates from '../libs/Dates'
import Numbers from '../libs/Numbers'
import API from '../libs/API'
import { Button, Statistic, Card } from "semantic-ui-react";
import BalancesPicture from '../modals/BalancesPicture'
import EtherPicture from '../modals/EtherPicture'
import Token from '../components/Token'
import ProductsHome from '../components/ProductsHome'
import FiatAdd from "../modals/FiatAdd";
import FiatWithdraw from "../modals/FiatWithdraw";
import TokenBuy from "../modals/TokenBuy";
import TokenSell from "../modals/TokenSell";
import TokenSend from "../modals/TokenSend";

const TICK_MS = 5000

export default class Home extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      username: '',
      info: {
        app: '',
        version: ''
      },
      userdata: null,
      redirect: false,
      products: [],
      fiatAddOpen: false,
      fiatWithdrawOpen: false,
      tokenBuyOpen: false,
      tokenBuyData: {
        name: '',
        code: '',
        toFiat: 1,
        fee_gain: 0,
        fee_workload: 0
      },
      tokenSellOpen: false,
      tokenSellData: {
        name: '',
        code: '',
        toFiat: 1,
        fee_gain: 0,
        fee_workload: 0
      },
      tokenSellMax: 0,

      tokenSendOpen: false,
      tokenSendData: {
        name: '',
        code: '',
        toFiat: 1,
        fee_gain: 0,
        fee_workload: 0
      },
      tokenSendMax: 0,    
      
      tx: []
    }

    this.buyTokenModal = this.buyTokenModal.bind(this)
    this.sellTokenModal = this.sellTokenModal.bind(this)
    this.sendTokenModal = this.sendTokenModal.bind(this)
    this.fiatDeposit = this.fiatDeposit.bind(this)
    //this.tokenBuy = this.tokenBuy.bind(this)
    this.buyTokenTransaction = this.buyTokenTransaction.bind(this)
    this.sellTokenTransaction = this.sellTokenTransaction.bind(this)
    this.addSomeMagicThatLooksLikeRealWorld = this.addSomeMagicThatLooksLikeRealWorld.bind(this)
    this.tick = null
    this.updateConfig = this.updateConfig.bind(this)
    this.updateUserWallet = this.updateUserWallet.bind(this)
    this.transactionLog = this.transactionLog.bind(this)
  }

  async componentWillUnmount(){
    clearInterval(this.tick)
  }

  async updateConfig(){
    console.log('update config - not implemented')
  }

  async transactionLog(payload){
    const username = await Storage.get('username')
    const d = new Date()
    const dateStr = Dates.formatDayTime(d)
    const dateTs = Dates.formatTs(d)
    await API.tx.addTx({
      user: username,
      dateStr,
      dateTs: parseInt(dateTs),
      ...payload
    })
  }

  async updateUserWallet(){
    try {
      console.log('update user wallet')
      const username = await Storage.get('username')
      const userdata = await Storage.get('userdata')  
      await API.users.setData(username, userdata)
    } catch (e) {
      console.log('updateUserWallet', e)
    }
  }

  async addSomeMagicThatLooksLikeRealWorld(){
    const config = Object.assign({}, this.state.config)
    
    config.tokens.forEach(element => {
      const delta = Numbers.getRandomFloat(-0.05, 0.05, 4)
      element.toFiat += delta
    });
    await this.setState({config: config})
    // 10% of update exchange rates for every session
    const tossADice = Numbers.getRandomFloat(1, 10)
    if(tossADice === 2){
      this.updateConfig()
    } 
  }

  async fiatDeposit(curr){
    const userdata = await Storage.get('userdata')
    // console.log(`deposit ${curr} from ${userdata.fiat_balance} `)
    userdata.fiat_balance = parseFloat(userdata.fiat_balance) + parseFloat(curr)
    await Storage.set('userdata', userdata)
    this.setState({
      fiatAddOpen: false,
      fiatWithdrawOpen: false,
      userdata
    })
    await this.updateUserWallet()
    await this.transactionLog({type:'deposit.fiat', amount: curr})
  }
  
  async buyTokenTransaction(curr){
    const userdata = await Storage.get('userdata')
    userdata.fiat_balance = parseFloat(userdata.fiat_balance) - parseFloat(curr.fiat)
    let tokenIndex = userdata.tokens.findIndex(i=>i.code === curr.currencyCode)
    if (tokenIndex<0){
      userdata.tokens.push({
        code: curr.currencyCode,
        amount: 0
      })
      tokenIndex = userdata.tokens.length - 1
    }
    const amount = userdata.tokens[tokenIndex].amount || 0
    userdata.tokens[tokenIndex].amount = amount + curr.tokens
    // console.log(`deposit ${curr} from ${userdata.fiat_balance} `)
    // userdata.fiat_balance = parseFloat(userdata.fiat_balance) + parseFloat(curr)

    await Storage.set('userdata', userdata)
    // TODO
    // API sync
    await this.setState({
      tokenBuyOpen: false,
      userdata
    })
    // console.log(curr)
    await this.updateUserWallet()
    await this.transactionLog({type:'buy.token', ...curr})
  }
  
  async sellTokenTransaction(curr){
    const userdata = await Storage.get('userdata')
    userdata.fiat_balance = parseFloat(userdata.fiat_balance) + parseFloat(curr.fiat)
    let tokenIndex = userdata.tokens.findIndex(i=>i.code === curr.currencyCode)
    if (tokenIndex<0){
      userdata.tokens.push({
        code: curr.currencyCode,
        amount: 0
      })
      tokenIndex = userdata.tokens.length - 1
    }
    const amount = userdata.tokens[tokenIndex].amount || 0
    userdata.tokens[tokenIndex].amount = amount - curr.tokens
    // console.log(`deposit ${curr} from ${userdata.fiat_balance} `)
    // userdata.fiat_balance = parseFloat(userdata.fiat_balance) + parseFloat(curr)

    await Storage.set('userdata', userdata)

    await this.setState({
      tokenSellOpen: false,
      userdata
    })
    await this.updateUserWallet()
    await this.transactionLog({type:'sell.token', ...curr})
  }
  
  async buyTokenModal(tokData){
    // console.log('buy', tokData)
    this.setState({
      tokenBuyOpen: true,
      tokenBuyData: tokData
    })
  }

  async sellTokenModal(tokData, max){
    // console.log('sell', tokData)
    this.setState({
      tokenSellOpen: true,
      tokenSellData: tokData,
      tokenSellMax: max
    })
  }
  
  async sendTokenModal(tokData, max){
    this.setState({
      tokenSendOpen: true,
      tokenSendData: tokData,
      tokenSendMax: max
    })
    
  }

  async componentDidMount(){
      let config = await Storage.get('config')
      if (config === null || config.length === 0 ) {
        config = await API.config.getC()
        await Storage.set('config', config)
      }
      const info = await API.info.status()
      const username = await Storage.get('username')
      const userdata = await API.users.getData(username)
      const products = await API.products.getProd({username})
      await Storage.set('userdata', userdata)
      this.setState({
          username,
          info,
          userdata,
          config,
          products
      })
      this.tick = setInterval(
        this.addSomeMagicThatLooksLikeRealWorld,
        TICK_MS
      )      
  }

    render() {
      return <PageTemplate
                  page='Home'
              >
  
          {
            this.state.userdata === null
            ? null
            : <>
                <div>
                  This is the home page. It should be quite clear without distraction.<br />
                  With nice graphics should looks like <BalancesPicture /><br />
                  Every Tokens should open smthing like <EtherPicture /> 
                </div>
                <h1>
                <Statistic inverted>
                  
                  <Statistic.Value>{Numbers.toCurrency(this.state.userdata.fiat_balance)} &euro;</Statistic.Value>
                  <Statistic.Label>Balance</Statistic.Label>
                </Statistic>

                  <br />
                  <Button onClick={()=>{
                    this.setState({
                      fiatAddOpen: true
                    })
                  }}>Add Money</Button>
                  <Button onClick={()=>{
                    this.setState({
                      fiatWithdrawOpen: true
                    })
                  }}>Witdraw</Button>
                  <FiatAdd
                    open={this.state.fiatAddOpen}
                    deposit={this.fiatDeposit}
                    close={()=>{
                      this.setState({
                        fiatAddOpen: false
                      })
                    }}
                    />
                  <FiatWithdraw
                    open={this.state.fiatWithdrawOpen}
                    max={this.state.userdata.fiat_balance}
                    deposit={async (amount)=>{
                      await this.fiatDeposit(amount * -1)
                    }}
                    close={()=>{
                      this.setState({
                        fiatWithdrawOpen: false
                      })
                    }}
                    />


                </h1>

                {
                  this.state.products.length > 0
                  ? <div>
                      <h2>NFTs</h2>
                      <ProductsHome products={this.state.products} />
                    </div>
                  : null
                }

                <h2>Investments</h2>
                <div >
                  <Card.Group >
                    {
                      this.state.config.tokens.map((i,k)=>{
                        const ut = this.state.userdata.tokens.filter(usertoken=>{
                          //console.log('redo')
                          return i.code === usertoken.code
                        })
                        return <Token 
                                  key={k}
                                  tokendata={i}
                                  usertoken={ut[0]}
                                  buyTokenModal={this.buyTokenModal}
                                  sellTokenModal={this.sellTokenModal}
                                  sendTokenModal={this.sendTokenModal}
                                  />
                      })
                    }
                  </Card.Group>
                  <TokenBuy
                    open={this.state.tokenBuyOpen}
                    max={this.state.userdata.fiat_balance}
                    buy={async (payload)=>{
                      await this.buyTokenTransaction(payload)
                      this.setState({tokenBuyOpen:false})
                    }}
                    close={()=>{
                      this.setState({
                        tokenBuyOpen: false
                      })
                    }}
                    tokenData={this.state.tokenBuyData}
                  />
                  <TokenSell
                    open={this.state.tokenSellOpen}
                    max={this.state.tokenSellMax}
                    sell={async (payload)=>{
                      await this.sellTokenTransaction(payload)
                      this.setState({tokenBuyOpen:false})
                    }}
                    close={()=>{
                      this.setState({
                        tokenSellOpen: false
                      })
                    }}
                    tokenData={this.state.tokenSellData}
                  />
                  <TokenSend
                    open={this.state.tokenSendOpen}
                    max={this.state.tokenSendMax}
                    send={async (payload)=>{
                      // await this.sendTokenTransaction(payload)
                      this.setState({tokenSendOpen:false})
                    }}
                    close={()=>{
                      this.setState({
                        tokenSendOpen: false
                      })
                    }}
                    tokenData={this.state.tokenSendData}
                  />
                </div>

                </>
          }
      </PageTemplate>
    }
}