import React, {useState} from "react";
import { Segment, Form, Button, Icon, Popup } from "semantic-ui-react";
import { useNavigate } from "react-router-dom"
import Storage from '../libs/Storage'
import "./Login.css";

function reverseString(str) {
  return str.split("").reverse().join("");
}

export default function Login(){
  const navigate = useNavigate()
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passError, setPassError] = useState(null);
 
  return (
    <div className="logincontainer">
      <Segment  className="segment-container">
        <Form
          
          className="form-container"
          onSubmit={async (data)=>{
            const nameuser = reverseString(username)
            if (username !== '' && nameuser === password) {
              await Storage.set('username', username)
              navigate('/home')
            } else {
              setPassError({
                content: 'wrong password'
              })
            }
          }}
        >
          <Form.Group widths="equal">
            <Icon name="user" />
            <Popup
              trigger={
                <Form.Input
                  name="username"
                  fluid
                  label="username"
                  placeholder="john_doe"
                  onChange={async (e, { name, value }) => {
                    setPassError(null)
                    setUsername(value);
                  }}      
                />
              }
            >
              <Popup.Content>
                Type your username
              </Popup.Content>
            </Popup>
          </Form.Group>
          <Form.Group widths="equal">
            <Icon name="user secret" />
            <Popup
              trigger={
                <Form.Input
                  name="password"
                  fluid
                  label="password"
                  placeholder="*****"
                  type="password"
                  error={passError}
                  onChange={async (e, { name, value }) => {
                    setPassError(null)
                    setPassword(value)
                  }}
                />
              }
            >
              <Popup.Content>Type your password</Popup.Content>
            </Popup>
          </Form.Group>
          <Button animated fluid type="submit">
            <Button.Content visible>Log In</Button.Content>
            <Button.Content hidden>
              <Icon name="sign-in" />
            </Button.Content>
          </Button>
        </Form>
      </Segment>
    </div>
  )
}
