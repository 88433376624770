import React from "react"
import { Button, Container, Header, Menu, Segment, Icon, Dropdown } from "semantic-ui-react";
import { Link, Navigate } from "react-router-dom"
import Storage from '../libs/Storage'
import API from '../libs/API'

export default class PageTemplate extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        info: {
          app: '',
          version: ''
        }
      }
      this.logout = this.logout.bind(this)
    }

    async componentDidMount(){
      const info = await API.info.status()
      const username = await Storage.get('username')
      this.setState({info, username})
    }

    async logout(){
      await Storage.deleteAll()
      this.setState({redirect: true})
    }


    render() {

        return <>

          {
            this.state.redirect
            ? <Navigate to="/" />
            : null
          }
           <div className="App">
          <Segment inverted vertical textAlign="center" style={{overflow: 'auto' }}>
            <Container as="nav">
              <Header inverted as="h1" style={{color: 'gold', textShadow: 'rgba(255,162,0,0.9) 0px 0px 10px'}}>
                  <Icon name='user outline'  />{this.state.username}
              </Header>
              <Menu borderless compact inverted>
                <Menu.Item  active={ (this.props.page==='Home')? true : false }><Link to="/home">Home</Link></Menu.Item>
                <Menu.Item  active={ (this.props.page==='The Forge')? true : false }><Link to="/shop">The Forge</Link></Menu.Item>
                <Menu.Item  active={ (this.props.page==='History')? true : false }><Link to="/history">History</Link></Menu.Item>
                <Menu.Item  active={ (this.props.page==='Company')? true : false }>
                  <Dropdown className="dropdown-company-menu" text='Company'>
                    <Dropdown.Menu >
                      <Dropdown.Item  className="ui"><Link  to="/companyvault">Virtual Vault</Link></Dropdown.Item>
                      <Dropdown.Item  className="ui"><Link  to="/companyrealvault">Real Vault</Link></Dropdown.Item>
                      <Dropdown.Item  className="ui"><Link  to="/companyforge">Forge Salesman</Link></Dropdown.Item>
                      <Dropdown.Item  className="ui"><Link  to="/companyhistory">TX Explore</Link></Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </Menu.Item>
                <Menu.Item>
                  <Button onClick={this.logout} size='small'>
                    Logout
                  </Button>
                </Menu.Item>
              </Menu>
            </Container>
            <Container className="content">
            {this.props.children}
            </Container>
            </Segment>
          <span className="footer">
            BackendAPI: {this.state.info.app} {this.state.info.version}
          </span>
          </div>
        </>
    }
}
