import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Menu
} from "semantic-ui-react";

import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Grid stackable container>
          <Grid.Row>
            <Header size="large" as="h3">
              Foglietti HOME
            </Header>
            <Menu fluid stackable widths="six" size="huge">
              <Menu.Item active>Home</Menu.Item>
              <Menu.Item>Application</Menu.Item>
              <Menu.Item>Company</Menu.Item>
              <Menu.Item>Team</Menu.Item>
              <Menu.Item>Mission</Menu.Item>
              <Menu.Item>Contact</Menu.Item>
            </Menu>
          </Grid.Row>
          <Divider hidden section />
          <Divider hidden section />
          <Grid.Row>
            <Container textAlign="center">
              
                <Header as="h1" size="huge">
                  DEMO TIME!
                </Header>

              <p>
                Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                Fusce dapibus, tellus ac cursus commodo, tortor mauris
                condimentum nibh, ut fermentum massa justo sit amet.
              </p>
              <Link to="/login">
                <Button color="green" size="massive">
                  LOGIN
                </Button>
              </Link>
            </Container>
          </Grid.Row>
          <Divider hidden section />
          <Divider hidden section />
          <Grid.Row columns="three">
            <Grid.Column>
              <Header size="huge" as="h1">
                Heading
              </Header>
              <p>
                Donec id elit non mi porta gravida at eget metus. Fusce dapibus,
                tellus ac cursus commodo, tortor mauris condimentum nibh, ut
                fermentum massa justo sit amet risus. Etiam porta sem malesuada
                magna mollis euismod. Donec sed odio dui.
              </p>
              <Button size="small" primary>
                View details &raquo;
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Header size="huge" as="h1">
                Heading
              </Header>
              <p>
                Donec id elit non mi porta gravida at eget metus. Fusce dapibus,
                tellus ac cursus commodo, tortor mauris condimentum nibh, ut
                fermentum massa justo sit amet risus. Etiam porta sem malesuada
                magna mollis euismod. Donec sed odio dui.
              </p>
              <Button size="small" primary>
                View details &raquo;
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Header size="huge" as="h1">
                Heading
              </Header>
              <p>
                Donec id elit non mi porta gravida at eget metus. Fusce dapibus,
                tellus ac cursus commodo, tortor mauris condimentum nibh, ut
                fermentum massa justo sit amet risus. Etiam porta sem malesuada
                magna mollis euismod. Donec sed odio dui.
              </p>
              <Button size="small" primary>
                View details &raquo;
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Divider hidden section />
          <Grid.Row>
            <Grid.Column>
              <Divider />
              <footer>&copy; 2022 Meltatech</footer>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default App;