import React, { useEffect, useState } from 'react'
import { Card, Button, Grid, Message} from 'semantic-ui-react'
// import Dates from '../../libs/Dates'
import Numbers from '../../libs/Numbers'

function ForgeItem(props){

    const name =props.item.name
    const weight = props.item.weight
    const description = props.item.description
    const usertokens = props.userdata.tokens || []
    
    const [tokens, setTokens] = useState([])
    useEffect(()=>{
        const tRaw = props.tokens || []
        const clean = tRaw.map(tr=>{
            let amountX = props.item.cost.filter(x => x.currency === tr.code)
            let cost = 0
            let spread = 0
            if (amountX.length>0){
                cost = amountX[0].cost
                spread = amountX[0].spread
            }
            return {
                currency: tr.code,
                cost,
                spread,
            }
        })
        // console.log(clean)
        setTokens(clean)
    }, [props.item.cost, props.item.tokens, props.tokens])
    return <Card fluid style={{textAlign: 'left'}}>
        <Card.Content>
            <Card.Header>
                <h1>{name}</h1> 
                <p>
                    {weight} metal grams   
                </p>    
            </Card.Header>
            <Card.Description>
                {description}
            </Card.Description>
            <Card.Meta>
            <br />
            
                    <h3>Costs</h3>

                    {
                        tokens.map((t, pos)=>{
                            if(t.cost <= 0){
                                return null
                            }
                            const spreaded = (t.cost / 100) * t.spread
                            const net = t.cost
                            const gross = net + spreaded
                            t.selled_price = gross
                            const userWallet = usertokens.filter(i=> i.code === t.currency)
                            let canBuy = false
                            if(userWallet.length > 0 && userWallet[0].amount >= t.selled_price){
                                canBuy = true
                            }
                            return <Grid key={pos} columns='equal'>
                                <Grid.Row>
                                <Grid.Column>
                                    <b>net cost</b> {Numbers.toCurrency(t.cost)} {t.currency} + <b>fee cost</b> {Numbers.toCurrency(spreaded)} {t.currency} ({Numbers.toPercentage(t.spread)})
                                </Grid.Column>
                                <Grid.Column >
                                {
                                    canBuy
                                    ? <Button color="green" onClick={()=>props.buyProduct(t)}>BUY FOR {Numbers.toCurrency(gross)} {t.currency}</Button>
                                    : <Message warning>
                                    <Message.Header>Price {Numbers.toCurrency(gross)} {t.currency}</Message.Header>
                                    <p style={{'fontSize': '.9em'}}>you don't have enough tokens</p>
                                  </Message>
                                }
                                    
                                </Grid.Column>
                                </Grid.Row>


                            </Grid>
                        })
                    }
            </Card.Meta>
        </Card.Content>
    </Card>
}

function ForgeTable(props){
    const tokens = props.tokens
    // const [products, setProducts] = useState(props.products)

    return   <Card.Group>
        {
        props.products.map((i, pos)=>{
            return <ForgeItem key={i.name} pos={pos} item={i} tokens={tokens} 
                userdata={props.userdata}
                buyProduct={(p)=>{
                        props.buyProduct(i, p)
                    }
                }
            />
        })
        }
    </Card.Group>
}

export default ForgeTable