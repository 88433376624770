import React from 'react'
import { Button,  Image, Modal } from 'semantic-ui-react'

function BalancesPicture() {
  const [open, setOpen] = React.useState(false)

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button basic color='blue' size='mini'>Balances PAGE Example</Button>}
    >
      <Modal.Header>Balances PAGE Example</Modal.Header>
      <Modal.Content image>
        <Image  src='./images/balances.png'  />
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Ok
        </Button>

      </Modal.Actions>
    </Modal>
  )
}

export default BalancesPicture
