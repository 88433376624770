import ApiRest from './ApiRest'

const apiRest = new ApiRest(process.env.REACT_APP_APIURL)

const getC = async() => {
    const res = await apiRest.call('/foglietti/get_config', {
        method: 'get',
    })
    return res.data
}

const setC = async(config) => {
    const res = await apiRest.call('/foglietti/set_config', {
        method: 'put',
        data: config
    })
    return res.data
}

const config = {
    getC: getC,
    setC: setC,
}

export default config
