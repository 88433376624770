import React from "react"
import {Table} from "semantic-ui-react"
import PageTemplate from "./PageTemplate"
import Numbers from '../libs/Numbers'
import API from '../libs/API'
// import Storage from '../libs/Storage'
export default class CompanyVault extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        users: [],
        vaultRows: [],
        usersVaultRows: [],
      }
    }

    async componentDidMount(){
      const { users } = await API.users.getAll()
      //console.log(users)
      const vaultRows = []
      vaultRows.push({
        first:  'Users',
        second: users.length
      })
      const howManyCOIN = users.reduce((p, c)=>{
        p[0] = p[0] + c.fiat_balance
        const lib = c.tokens.filter(x=>x.code === 'LIB')
        const libCount = (lib && lib[0]) ? lib[0].amount : 0
        p[1] = p[1] + libCount
        return p
      }, [0, 0])
      vaultRows.push({
        first:  'FIAT in BANK',
        second: `${Numbers.toCurrency(howManyCOIN[0])} EURO`
      })
      vaultRows.push({
        first:  'Token Libero in BANK',
        second: `${Numbers.toFloat(howManyCOIN[1])} LIB`
      })

      const usersVaultRows = []
      users.forEach(element => {
        const lib = element.tokens.filter(x=>x.code === 'LIB')
        const libCount = (lib && lib[0]) ? lib[0].amount : 0
        usersVaultRows.push({
          name: element.id,
          amount: `${Numbers.toCurrency(element.fiat_balance)} EURO`,
          libero: `${Numbers.toFloat(libCount)} LIB`,
          currencies: element.tokens.length
        })
      });
      this.setState({
        users,
        vaultRows,
        usersVaultRows
      })
    }

    render() {
      return <PageTemplate
                  page='Company'
              >

              <p>
                <b>COMPANY ONLY PAGE</b>
                <br />
                Users can NOT view this page
              </p>

              <p>
                This represent a kind of dashboard for bird view of the company healt/wealth/status
              </p>
              <h1>Vault</h1>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Entity</Table.HeaderCell>
                    <Table.HeaderCell>Count</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {
                    this.state.vaultRows.map(i=>{
                      return                   <Table.Row>
                        <Table.Cell>{i.first}</Table.Cell>
                        <Table.Cell>{i.second}</Table.Cell>
                      </Table.Row>
                    })
                  }

                </Table.Body>

              </Table>

 <h1>Users Vault</h1>

              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Euro</Table.HeaderCell>
                    <Table.HeaderCell>Token Libero</Table.HeaderCell>
                    <Table.HeaderCell>How many currencies</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                {
                    this.state.usersVaultRows.map(i=>{
                      return                   <Table.Row>
                        <Table.Cell>{i.name}</Table.Cell>
                        <Table.Cell>{i.amount}</Table.Cell>
                        <Table.Cell>{i.libero}</Table.Cell>
                        <Table.Cell>{i.currencies}</Table.Cell>
                      </Table.Row>
                    })
                  }
                </Table.Body>

              </Table>

              </PageTemplate>
    }
}
