import React from "react"

import PageTemplate from "./PageTemplate"
import ForgeTablePublic from "../components/ForgeTablePublic"
import Storage from '../libs/Storage'
import Dates from "../libs/Dates"
import API from "../libs/API"
// import API from "../libs/API"

export default class TheForge extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        products: [],
        tokens: [],
        userdata: null
      }
      this.buyProduct = this.buyProduct.bind(this)
    }

    async componentDidMount(){
      const config = await Storage.get('config')
      const userdata = await Storage.get('userdata')
      //console.log(config)
      this.setState({
        products: config.products,
        tokens: config.tokens,
        userdata
      })
    }

    async buyProduct(p, i){
      const username = await Storage.get('username')
      const userdata = await Storage.get('userdata')
      const d = new Date()
      const product = {
        prod: p,
        cost: i,
        username,
        date: Dates.formatDayTime(d)
      }
      for(let x = 0; x < userdata.tokens.length; x++){
        console.log('TheForge', userdata.tokens[x], i)
        if (userdata.tokens[x].code === i.currency){
          userdata.tokens[x].amount = userdata.tokens[x].amount - i.selled_price
        }
      }
      // if(!userdata.products){
      //   userdata.products = []
      // }
      // userdata.products.push(product)
      await API.users.setData(username, userdata)
      await API.products.addProd(product)
      await Storage.set('userdata', userdata)
      this.setState({
        userdata
      })
    }

    render() {
      return <PageTemplate
                  page='The Forge'
              >
                <h1>The Forge</h1>
                <p className="pageinfo">
                This is a kind of market place for users.
                <br />
                <br />
                Products are created on the Company admin area.<br />
                When you buy a products we'll create a new NFT token owned by you and we store the physical object in our vault.<br/>
                When you want to get the product we would swap the real object for the token (then we burn the token)<br />

                </p>
                ForgeTablePublic
                {
                  this.state.products.length > 0
                  ? <>
                    <h2>products</h2>
                    <ForgeTablePublic 
                      products={this.state.products}
                      tokens={this.state.tokens}
                      userdata={this.state.userdata}
                      buyProduct={this.buyProduct}
                      />
                  </>
                  : null
                }                
              </PageTemplate>
    }
}
