import info from './info'
import users from './users'
import config from './config'
import tx from './tx'
import products from './products'

const API = {
    info,
    users,
    config,
    tx: tx,
    products: products,
}

export default API;
