import Loader from './Loader';
import App from './App';
import Login from './pages/Login';
import Home from './pages/Home';
import TheForge from './pages/TheForge';
import History from './pages/History';
import CompanyVault from './pages/CompanyVault';
import CompanyHistory from './pages/CompanyHistory';
import CompanyForge from './pages/CompanyForge';
import CompanyRealVault from './pages/CompanyRealVault';

const routes = [
    {
        path: '/',
        element: <App/>,
        loader: () => <Loader />,
        errorElement: (err) => {
            console.log(err, 'errorElement')
        }
    },
    {
        path: '/login',
        element: <Login />,
        loader: () => <Loader />,
    },
    {
        path: '/home',
        element: <Home />,
        loader: () => <Loader />,
    },
    {
        path: '/shop',
        element: <TheForge />,
        loader: () => <Loader />,
    },
    {
        path: '/history',
        element: <History />,
        loader: () => <Loader />,
    },
    {
        path: '/companyvault',
        element: <CompanyVault />,
        loader: () => <Loader />,
    },
    {
        path: '/companyhistory',
        element: <CompanyHistory />,
        loader: () => <Loader />,
    },
    {
        path: '/companyforge',
        element: <CompanyForge />,
        loader: () => <Loader />,
    },
    {
        path: '/companyrealvault',
        element: <CompanyRealVault />,
        loader: () => <Loader />,
    },
]
export default routes;