import React, { useEffect, useState } from 'react'
import { Card, Input, Label, Form, TextArea, Button, Grid} from 'semantic-ui-react'
// import Dates from '../../libs/Dates'
// import Numbers from '../../libs/Numbers'

function ForgeItem(props){
    const [hasChanges, setHasChanges] = useState(false)
    const [name, setName] = useState(props.item.name)
    const [weight, setWeight] = useState(props.item.weight)
    const [description, setDescription] = useState(props.item.description)

    const [tokens, setTokens] = useState([])
    useEffect(()=>{
        const tRaw = props.tokens || []
        const clean = tRaw.map(tr=>{
            let amountX = props.item.cost.filter(x => x.currency === tr.code)
            let cost = 0
            let spread = 0
            if (amountX.length>0){
                cost = amountX[0].cost
                spread = amountX[0].spread
            }
            return {
                currency: tr.code,
                cost,
                spread
            }
        })
        // console.log(clean)
        setTokens(clean)
    }, [props.item.cost, props.item.tokens, props.tokens])
    return <Card fluid style={{textAlign: 'left'}}>
        <Card.Content>
            <Card.Header>
                <Input value={name} 
                    onChange={e => {
                        setName(e.target.value)
                        setHasChanges(true)
                        }
                    }
                    />
                <Label  pointing='left'>Name</Label>
                <Input value={weight}  size='mini'
                    onChange={e => {
                        setWeight(e.target.value)
                        setHasChanges(true)
                        }
                    }
                    label={{ basic: true, content: 'g' }}
                    labelPosition='right'                    
                    />
                <Label  pointing='left'>Metal Weight</Label>
            </Card.Header>
            <Card.Meta>
                    <h3>Costs</h3>

                    {
                        tokens.map((t, pos)=>{
                            return <Grid key={pos} columns='equal'>
                                <Grid.Row>
                                <Grid.Column>
                                    <Input value={t.cost} onChange={e=>{
                                        const newTS = Object.assign([], tokens)

                                        setHasChanges(true)
                                        const index = tokens.findIndex(i => i.currency === t.currency)
                                        newTS[index].cost = e.target.value
                                        setTokens(newTS)
                                    }}
                                    label={{ basic: true, content: t.currency }}
                                    labelPosition='right'
                                    />  
                                </Grid.Column>

                                <Grid.Column >
                                    Spread: 
                                    <Input value={t.spread} onChange={e=>{
                                        const newTS = Object.assign([], tokens)

                                        setHasChanges(true)
                                        const index = tokens.findIndex(i => i.currency === t.currency)
                                        newTS[index].spread = e.target.value
                                        setTokens(newTS)
                                    }}
                                    label={{ basic: true, content: '%' }}
                                    labelPosition='right'
                                    />
                                </Grid.Column>
                                </Grid.Row>


                            </Grid>
                        })
                    }
            </Card.Meta>
            <Card.Description>
                <Form>
                <Label pointing='below'>Descriptions:</Label>
                <TextArea value={description} onChange={e => {
                        setHasChanges(true)
                        setDescription(e.target.value)
                    }}/>
            </Form>
            <br />
            {
                hasChanges === true
                ? <Button color='green' onClick={e=>{
                        props.saveProduct({pos: props.pos, cost: tokens, name, description, weight: weight})
                        setHasChanges(false)    
                    }}>Save</Button>
                : null
            }
            <Button color='red' onClick={e=>props.deleteProduct(props.pos)}>Delete</Button>
            </Card.Description>
        </Card.Content>
    </Card>
}

function ForgeTablePublic(props){
    const tokens = props.tokens
    const [products, setProducts] = useState(props.products)

    return   <Card.Group>
        {
        products.map((i, pos)=>{
            console.log(i)
            return <ForgeItem key={i.name} pos={pos} item={i} tokens={tokens} 
                saveProduct={(p)=>{
                        const newP = {
                            name: p.name,
                            weight: parseInt(p.weight) || 1,
                            description: p.description,
                            cost: p.cost.map(cc=>{
                                return {
                                    currency: cc.currency,
                                    cost: parseFloat(cc.cost) || 0,
                                    spread: parseFloat(cc.spread) || 0
                                }
                            })
                        }
                        products[p.pos] = newP
                        setProducts(products)
                        props.saveProducts(products)
                    }
                }
                deleteProduct={(p)=>{
                        products.splice(p, 1)
                        setProducts(products)
                        props.saveProducts(products)
                    }
                }
            />
        })
        }
    </Card.Group>
}

export default ForgeTablePublic