import React from 'react'
import { Button, Header, Modal, Input, Label } from 'semantic-ui-react'
import Numbers from '../libs/Numbers'

export default class FiatAdd  extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            amount: 100.00
        }
    }
    render(){
        return <Modal
        onClose={() => this.props.close()}
        open={this.props.open}
      >
        <Modal.Header>EURO</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Header>Deposit interface</Header>
            <div>
              this place represent many differents gateway/interfaces FROM the user TO the Company Bank account:
                <ul>
                    <li>Credit CARD (as a service, not by ourself)</li>
                    <li>Bank Transfer</li>
                    <li>Other services like PAYPAL / Satispay (?!?) / Applepay etc etc</li>
                </ul>
            </div>
            <h2>
                How Much do you want to deposit?<br />
                <Input labelPosition='right' type='text' placeholder='Amount'>
                    <Label basic>&euro;</Label>
                    <input 
                        value={this.state.amount}
                        onChange={(e)=>{
                            const val = parseFloat(e.target.value)
                            if(isNaN(val)){
                                return
                            }
                            this.setState({
                                amount: val
                            })
                        }}
                        onBlur={()=>{
                            const val = Numbers.toCurrency(this.state.amount)
                            this.setState({
                                amount: val
                            })
                        }}
                        />
                </Input>                
            </h2>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => this.props.close()}>
            Nope
          </Button>
          <Button
            content="Deposit"
            labelPosition='right'
            icon='checkmark'
            onClick={() => this.props.deposit(this.state.amount)}
            positive
          />
        </Modal.Actions>
      </Modal>
    }
}