import React from 'react'
import { Button, Header, Modal, Input, Label, Message } from 'semantic-ui-react'
//import Numbers from '../libs/Numbers'

export default class TokenSend  extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            amountTokens: 0,
            touser: '',
            amountFee: 0, // ...???
            error: false
        }
        this.setTokens = this.setTokens.bind(this)
    }

    componentDidUpdate(prevProps){
      if(prevProps.tokenData.code !== this.props.tokenData.code){
        this.setState({
          amountTokens: 0,
          amountEuro: 0,
          amountFee: 0,
          error: false
        })
      }
    }

    async setTokens(e){
      let value = parseFloat(e.target.value)
      value = isNaN(value) ? 0 : value
      if(value<0){
        return
      }      
      if(value>this.props.max){
        this.setState({
          error: true
        })
      } else {      
        this.setState({
          amountTokens: value,
          //amountFee: fee,
          error: false
        }) 
      }     
    }
    render(){
      //console.log(this.props)
        return <Modal
              onClose={() => this.props.close()}
              open={this.props.open}
              >
        <Modal.Header>Send {this.props.tokenData.name}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Header>Easy {this.props.tokenData.code} interface</Header>
            <div>
            this place represent a basic TOKEN interface. We are making math with some dimensions like:
            
            <p>
                One step per time... still not implemented  
            </p>
            </div>
            <h2>
                How Much {this.props.tokenData.code} do you want to send? (fox max {this.props.max || 0} {this.props.tokenData.code}) <br />
                <Input labelPosition='right' type='text' placeholder='Amount'>
                    <Label basic style={{width: '100px'}}>{this.props.tokenData.code}</Label>
                    <input 
                        value={this.state.amountTokens}
                        onChange={this.setTokens}
                        />
                </Input>                
            </h2>
            {
              this.state.error
              ? <Message warning>
                  <Message.Header>You must deposit {this.props.tokenData.code}!</Message.Header>
                  <p>{this.props.tokenData.name} tokens are not enought.</p>
                </Message>  
              : null
            }
            {
              this.state.amountFee > 0
              ? <p>transaction cost (fee): {this.state.amountFee}&euro;</p> 
              : null
            }
               
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => {
            this.props.close()
            
            }}>
            Nope
          </Button>
          <Button
            content="Send not implemented :P"
            labelPosition='right'
            icon='checkmark'
            color='blue'
            onClick={() => {
              this.props.send({
                tokens: this.state.amountTokens,
                user: this.state.user,
                fee: this.state.amountFee, 
                currencyCode: this.props.tokenData.code            
              })
            }}
            
          />
        </Modal.Actions>
      </Modal>
    }
}