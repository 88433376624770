import ApiRest from './ApiRest'

const apiRest = new ApiRest(process.env.REACT_APP_APIURL)

const status = async() => {
    const res = await apiRest.call('/info/status', {
        method: 'get',
    })
    return res.data
}

const info = {
    status: status
}

export default info
