import React from 'react'
import { Button, Header, Modal, Input, Label } from 'semantic-ui-react'
import Numbers from '../libs/Numbers'

export default class FiatWithdraw  extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            amount: 100.00
        }
    }
    render(){
        return <Modal
        onClose={() => this.props.close()}
        open={this.props.open}
      >
        <Modal.Header>EURO</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Header>Withdraw interface</Header>
            <div>
            this place represent many differents gateway/interfaces FROM Company TO the user Bank account:
                <ul>
                    <li>Bank Transfer</li>
                    <li>Other services like PAYPAL / Satispay (?!?) / Applepay etc etc</li>
                </ul>
            </div>
            <h2>
                How Much do you want to withdraw? (Max {this.props.max}&euro;) <br />
                <Input labelPosition='right' type='text' placeholder='Amount'>
                    <Label basic>&euro;</Label>
                    <input 
                        value={this.state.amount}
                        onChange={(e)=>{
                            const val = parseFloat(e.target.value)
                            this.setState({
                                amount: val
                            })
                        }}
                        onBlur={()=>{
                            if(this.state.amount <= this.props.max) {
                              return
                            }
                            const val = Numbers.toCurrency(this.state.amount)
                            this.setState({
                                amount: val
                            })
                        }}
                        />
                </Input>                
            </h2>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => {
            this.props.close()
            this.setState({amount:100})
            }}>
            Nope
          </Button>
          <Button
            content="Withdraw"
            labelPosition='right'
            icon='checkmark'
            onClick={() => {
              if(parseFloat(this.state.amount) > parseFloat(this.props.max)) {
                return
              } 
              this.props.deposit(this.state.amount)
              this.setState({amount:100})
            
            }}
            positive
          />
        </Modal.Actions>
      </Modal>
    }
}