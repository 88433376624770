import React from 'react'
import { Table } from "semantic-ui-react"
// import Dates from '../../libs/Dates'
// import Numbers from '../../libs/Numbers'

  // Function to calculate the average of numbers
  function avg(arr) {
    if(arr && arr.length>0){
      var sum = 0;
   
      // Iterate the elements of the array
      arr.forEach(function (item, idx) {
        sum += item;
      });
   
      // Returning the average of the numbers
      return `${(sum / arr.length).toFixed(5)}`;
    } else {
      return `N/A`
    }
  }

function StatsRows(props){
  const txdata = props.txdata
  const currencies = ['EUR']
  
  const stats = {
    moves: {
      EUR: { buy: [], sell: []}
    }
  }
  //console.log(txdata)
  for(let i=0; i<txdata.length; i++){
      const el = txdata[i]
      if(el.currencyCode || el.type==='deposit.fiat') {
        if(!stats.moves[el.currencyCode]){
          currencies.push(el.currencyCode)
          stats.moves[el.currencyCode] = {
            buy: [],
            sell: []
          }
        }
        switch(el.type){
          case 'buy.token':
            stats.moves[el.currencyCode].buy.push(el.tokens / el.fiat)
            break
          case 'deposit.fiat':
            console.log('eur', el)
            if(el.amount>0){
              stats.moves['EUR'].buy.push(el.amount)
            } else {
              stats.moves['EUR'].sell.push(el.amount)
            }
            break
          case 'sell.token':
            stats.moves[el.currencyCode].sell.push(el.tokens / el.fiat)
            break
          default:
        }

      }
    }

    return   <Table celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Currency</Table.HeaderCell>
        <Table.HeaderCell>Buy Average</Table.HeaderCell>
        <Table.HeaderCell>Sell Average</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
        {
            currencies.map((i, pos)=>{

                const ti = stats.moves[i]
                return <Table.Row key={pos}>
                    <Table.Cell>{i}</Table.Cell>
                    <Table.Cell>{avg(ti.buy)}</Table.Cell>
                    <Table.Cell>{avg(ti.sell)}</Table.Cell>

              </Table.Row>
            })
        }   
    </Table.Body>
  </Table>
 

}

export default StatsRows